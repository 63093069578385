import React, { useEffect } from "react"
import firebase from "firebase/app"
import "firebase/analytics"

const Firebase = () => {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCKhJanT8K4_W-s6IqI-Sq0lBWsOlMVm14",
      authDomain: "elapp-298116.firebaseapp.com",
      projectId: "elapp-298116",
      storageBucket: "elapp-298116.appspot.com",
      messagingSenderId: "448662204539",
      appId: "1:448662204539:web:3bfc40aae258f2b733f2de",
      measurementId: "G-9Q25KHG4CV",
    }
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()

    if (window.ipcRenderer) {
      window.ipcRenderer.on("send-firebase-event", (event, data) => {
        const { type, attrs } = data
        console.log(`mandando firebase event: ${type} ${JSON.stringify(attrs)}`)
        firebase.analytics().logEvent(type, attrs)
      })

      window.ipcRenderer.on("set-user-id", (event, userid) => {
        console.log(`seteando userid: ${userid}`)
        firebase.analytics().setUserId(userid)
      })
    } else {
      console.log("firebase does not work here")
    }
  }, [])
  return (
    <div>
      You should not see this window, it was made for send events to Firebase.
    </div>
  )
}

export default Firebase
